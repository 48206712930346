import React from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import styles from './PaymentStatus.module.css';

const PaymentStatus = () => {
    const { identifier, status, txnId } = useParams()
    return (
        <div className={`${styles.container} ${status === 'success' ? styles.success : styles.failed}`}>
            <div className={styles.content}>
                <div className={styles.iconWrapper}>
                    {status === 'success' ? (
                        <div className={styles.successIcon}>✓</div>
                    ) : (
                        <div className={styles.failedIcon}>✗</div>
                    )}
                </div>
                <h1 className={styles.title}>
                    {status === 'success' ? 'Payment Successful!' : 'Payment Failed'}
                </h1>
                <p className={styles.message}>
                    {status === 'success'
                        ? 'Thank you for your payment. Your transaction was completed successfully.'
                        : 'We encountered an issue with your payment. Please try again or contact support.'}
                </p>
                <div className={styles.btnWrapper}>
                <Link to={`/gc/my-cards`} className={styles.goHomeButton}>
                    Go To Home
                </Link>
                </div>
            </div>
        </div>
    );
};

export default PaymentStatus;
